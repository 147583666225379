<script setup lang="ts">
// Kompoennt odpowiedzialny za filtry w wyszukiwarce

import type { SelectOptionT } from 'currenda-design-system/dist/types/components/controls/select.types';

import { fetchItemCategories, fetchAdditionalParams } from '@Services/filter.service';

import {
  EMainCategories,
  EFilterInputsName,
  EAuctionCategories,
  type IAdditionalFilter
} from '@Models/Item/filter.model';

import { useFilter } from '@Composables/filter';

import SelectValue from '@Components/filters/inputs/SelectValue.vue';
import TextValue from '@Components/filters/inputs/TextValue.vue';
import RadioValue from '@Components/filters/inputs/RadioValue.vue';
import RangeValue from '@Components/filters/inputs/RangeValue.vue';
import DateValue from '@Components/filters/inputs/DateValue.vue';

const AdditionalFilters = defineAsyncComponent(() => import('@Components/filters/AdditionalFilters.vue'));

const { $i18n } = useNuxtApp();

const mainCategories: SelectOptionT[] = [
  {
    label: $i18n.t(`${EFilterInputsName.MAIN_CATEGORY}.${EMainCategories[EMainCategories.REAL_ESTATE]}`),
    value: EMainCategories.REAL_ESTATE_NAME
  },
  {
    label: $i18n.t(`${EFilterInputsName.MAIN_CATEGORY}.${EMainCategories[EMainCategories.MOVABLE]}`),
    value: EMainCategories.MOVABLE_NAME
  }
];
const movableCategories = ref<SelectOptionT[]>([]);
const realEstateCategories = ref<SelectOptionT[]>([]);
const allSubCategories = ref<{ [key: string]: number }>({});
const additionalFilters = ref<IAdditionalFilter[]>([]);

const hasAnyCategories = computed(
  () => movableCategories.value.length > 0 || realEstateCategories.value.length > 0 || allSubCategories.value.length > 0
);

const {
  accordionModel,
  mainCategory,
  subCategory,
  notes,
  city,
  isMarginRequired,
  openingValue_lowerBound,
  openingValue_upperBound,
  startAuctionAt_lowerBound,
  startAuctionAt_upperBound,
  clearAllFilters,
  clearCheckboxDetails
} = useFilter();

// czyszczenie subCategory jeśli mainCategory się zmieni
watch(mainCategory, (_, previousValue) => {
  // jeśli nie było poprzednio żadnej kategorii to nie resetujemy subCategory
  if (previousValue) subCategory.value = '';
});

watch(subCategory, (value) => {
  getAdditionalFilters(value);
});

const isItemCategoriesDisabled = computed(() => {
  return mainCategory.value !== EMainCategories.REAL_ESTATE_NAME && mainCategory.value !== EMainCategories.MOVABLE_NAME;
});

const itemCategories = computed<SelectOptionT[]>(() => {
  if (mainCategory.value === EMainCategories.MOVABLE_NAME) return movableCategories.value;
  return realEstateCategories.value;
});

onMounted(async () => {
  await fetchCategories();
  await getAdditionalFilters(subCategory.value);
});

async function fetchCategories() {
  const response = await fetchItemCategories();
  response.forEach((item) => {
    if (item.category === EAuctionCategories.MOVABLE) {
      movableCategories.value.push({
        label: item.value,
        value: item.value
      });
    } else {
      realEstateCategories.value.push({
        label: item.value,
        value: item.value
      });
    }

    allSubCategories.value = {
      ...allSubCategories.value,
      [item.value]: item.key
    };
  });
}

async function getAdditionalFilters(subCategory: string) {
  clearAdditionalFilters();
  clearCheckboxDetails();

  if (!subCategory) return;

  additionalFilters.value = await fetchAdditionalParams(allSubCategories.value[subCategory]);
}

function clearAdditionalFilters() {
  accordionModel.value = [];
  additionalFilters.value = [];
}

function clearAllFiltersWithAdditionalFilters() {
  clearAllFilters();
  clearAdditionalFilters();
}
</script>

<template>
  <CdsPanel
    variant="plain"
    type="default"
    size="regular"
    box-shadow="3xs"
    header-bg-color="primary80"
    content-bg-color="surface20"
  >
    <template #header-main>
      <CdsText
        variant="normal-h3"
        color="hi-emphasis-on-color"
        >{{ $t('filters.name') }}</CdsText
      >
    </template>

    <div class="filters">
      <CdsText
        variant="normal-h5"
        color="hi-emphasis-on-surface"
      >
        {{ $t('filters.labels.specifyItem') }}
      </CdsText>

      <div>
        <SelectValue
          v-model="mainCategory"
          :items="mainCategories"
          :label="$t('filters.labels.itemType')"
        />
      </div>

      <div v-if="hasAnyCategories">
        <SelectValue
          v-model="subCategory"
          :items="itemCategories"
          :is-disabled="isItemCategoriesDisabled"
          :label="$t('filters.labels.itemCategory')"
        />
      </div>

      <div>
        <TextValue
          v-model="notes"
          :label="$t('filters.labels.keyWords')"
        />
      </div>

      <div>
        <TextValue
          v-model="city"
          :label="$t('filters.labels.localisation')"
        />
      </div>

      <CdsText
        variant="normal-h5"
        color="hi-emphasis-on-surface"
      >
        {{ $t('filters.labels.general') }}
      </CdsText>

      <div>
        <RadioValue
          v-model="isMarginRequired"
          :label="$t('filters.labels.warranty')"
        />
      </div>

      <div>
        <RangeValue :label="$t('filters.labels.price.call')">
          <template #from>
            <TextValue
              v-model="openingValue_lowerBound"
              :label="`${$t('filters.labels.from')}:`"
            />
          </template>
          <template #to>
            <TextValue
              v-model="openingValue_upperBound"
              :label="`${$t('filters.labels.to')}:`"
            />
          </template>
        </RangeValue>
      </div>

      <div>
        <RangeValue :label="$t('filters.labels.startAuction')">
          <template #from>
            <DateValue
              v-model="startAuctionAt_lowerBound"
              :label="`${$t('filters.labels.from')}:`"
            />
          </template>
          <template #to>
            <DateValue
              v-model="startAuctionAt_upperBound"
              :label="`${$t('filters.labels.to')}:`"
            />
          </template>
        </RangeValue>
      </div>

      <AdditionalFilters
        v-if="additionalFilters.length"
        :params="additionalFilters"
      />

      <CdsBtn
        variant="outlined"
        type="primary"
        size="regular"
        @click="clearAllFiltersWithAdditionalFilters()"
      >
        {{ $t('filters.clearAll') }}
      </CdsBtn>
    </div>
  </CdsPanel>
</template>

<style lang="scss" scoped>
@use '~core/cds-assets/scss/helpers' as *;
@use '~core/assets/scss/mixins' as mixins;

.filters {
  display: flex;
  flex-direction: column;
  gap: cdsSpace('md');
  padding: cdsSpace('md');
  overflow-y: auto;
  max-height: calc(100vh - #{cdsSpace('xl')} - 48px);
  @include mixins.customScrollbar();

  @include cdsMediaQueryDown('lg') {
    max-height: unset;
  }
}
</style>
