<script setup lang="ts">
// Strona wyszukiwarki

import { useFilter } from '@Composables/filter';

import { useFilterStore } from '@Store/filter.module';

import { type TQueryWithFilters } from '@Models/Item/filter.model';

import Breadcrumbs from '@Components/Breadcrumbs.vue';
import MainFilters from '@Components/filters/MainFilters.vue';
import SortBar from '@Components/filters/SortBar.vue';
import Card from '@Components/index/Card.vue';

const route = useRoute();

const filterStore = useFilterStore();
const { getCount, getItems } = storeToRefs(filterStore);
const { limit, loading, pageNumber, createObjectForSearching, onChangePage } = useFilter();

const numberOfPages = computed(() => {
  if (getCount.value === null) return 0;

  return Math.ceil(getCount.value / limit.value);
});

watch(
  () => route.query,
  (newQuery, prevQuery) => {
    // jeśli offset się nie zmienił to znaczy że filtr się zmienił i ustawiamy strone na pierwszą co spowoduje wywołanie createObjectForSearching
    if (prevQuery?.offset && newQuery.offset && prevQuery?.offset === newQuery.offset) {
      onChangePage(1);
    } else {
      // jeśli offset się zmienił
      createObjectForSearching(newQuery as TQueryWithFilters);
    }
  },
  {
    immediate: true
  }
);
</script>

<template>
  <CdsRow justify="center">
    <CdsCol
      cols="12"
      lg="11"
      xl="10"
    >
      <Breadcrumbs class="w-100 my-md" />

      <CdsRow class="align-start">
        <CdsCol
          cols="12"
          lg="3"
          md="4"
          class="filters-sticky"
        >
          <MainFilters />
        </CdsCol>

        <CdsCol
          cols="12"
          lg="9"
          md="8"
        >
          <SortBar />

          <div class="container__items">
            <!-- loading -->
            <div
              v-if="loading"
              class="w-100"
            >
              <CdsRow class="text-center">
                <CdsCol
                  v-for="n in 12"
                  :key="n"
                  cols="12"
                  sm="6"
                  lg="4"
                >
                  <CdsSkeletonLoader
                    class="mt-4"
                    type="card"
                  ></CdsSkeletonLoader>
                </CdsCol>
              </CdsRow>
            </div>

            <!-- no results -->
            <div
              v-else-if="!getItems.length"
              class="w-100"
            >
              <CdsText>
                {{ $t('filters.noResults.title') }}
              </CdsText>
              <CdsText>
                {{ $t('filters.noResults.message') }}
              </CdsText>
            </div>

            <!-- results -->
            <CdsRow
              v-else
              class="justify-center"
              id="item-list-container"
            >
              <CdsCol
                v-for="item in getItems"
                :key="item.id"
                cols="12"
                sm="6"
                lg="4"
              >
                <Card
                  :card="item"
                  :key="item.id"
                  :show-start-auction-with-label="false"
                >
                  <template #title>
                    {{ item.title }}
                  </template>
                </Card>
              </CdsCol>
            </CdsRow>

            <div
              v-if="getItems.length && numberOfPages > 1"
              class="d-flex justify-center my-2xl"
            >
              <CdsPagination
                :model-value="pageNumber"
                variant="plain"
                type="default"
                size="regular"
                :length="numberOfPages"
                :total-visible="limit"
                @update:model-value="onChangePage"
              />
            </div>
          </div>
        </CdsCol>
      </CdsRow>
    </CdsCol>
  </CdsRow>
</template>

<style lang="scss" scoped>
@use '~core/cds-assets/scss/helpers' as *;

.container__items {
  margin-top: cdsSpace('lg');
}

.filters-sticky {
  position: sticky;
  top: 0;
  left: 0;
  margin-bottom: cdsSpace('lg');

  @include cdsMediaQueryDown('lg') {
    position: static;
    margin-bottom: 0;
  }
}

.item-list-container {
  @include cdsMediaQueryDown('lg') {
    padding: 0 cdsSpace('xs');

    :deep(.card) {
      margin-bottom: cdsSpace('sm');
    }
  }
}
</style>
