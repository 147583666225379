<script setup lang="ts">
defineProps<{
  label: string;
}>();

const radioValue = defineModel<boolean>();
</script>

<template>
  <CdsText
    variant="normal-body"
    color="mid-emphasis-on-surface"
    class="d-flex"
  >
    {{ label }}
  </CdsText>
  <CdsRadioGroup
    v-model="radioValue"
    variant="plain"
    type="default"
    size="small"
  >
    <CdsRadio
      :value="false"
      :label="$t('filters.labels.nonRequired')"
    />
    <CdsRadio
      :value="true"
      :label="$t('filters.labels.required')"
    />
  </CdsRadioGroup>
</template>
