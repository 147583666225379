<script setup lang="ts">
import { formatDate } from '~coreUtils/date';

defineProps<{
  label: string;
}>();

const dateValue = defineModel<Date>();
const setDate = computed(() => {
  if (!dateValue.value) return;
  return formatDate(dateValue.value.toString());
});
</script>

<template>
  <CdsText
    variant="normal-body"
    color="mid-emphasis-on-surface"
    class="d-flex"
  >
    {{ label }}
  </CdsText>

  <CdsDateInput
    v-model="dateValue"
    :input-value="setDate"
    variant="outlined"
    type="default"
    size="regular"
    :hide-details="true"
    clearable
  />
</template>
