<script setup lang="ts">
import type { SelectOptionT } from 'currenda-design-system/dist/types/components/controls/select.types';

const { clearable = true, isDisabled = false } = defineProps<{
  clearable?: boolean;
  label: string;
  items: SelectOptionT[];
  isDisabled?: boolean;
}>();

const selectedValue = defineModel<number | string>();
</script>

<template>
  <CdsText
    variant="normal-body"
    color="mid-emphasis-on-surface"
    class="d-flex"
  >
    {{ label }}
  </CdsText>

  <CdsSelect
    v-model="selectedValue"
    :options="items"
    :disabled="isDisabled"
    variant="outlined"
    type="default"
    size="regular"
    mandatory
    :clearable="clearable"
  />
</template>
