<script setup lang="ts">
// Komponent odpowiedzialny za sortowanie i limitowanie wyników w wyszukiwarce

import { useFilter } from '@Composables/filter';

import { EFilterFlags, EFilterSeparator, type ISortBar } from '@Models/Item/filter.model';

import SelectValue from '@Components/filters/inputs/SelectValue.vue';

const { $i18n } = useNuxtApp();
const { sort, limit } = useFilter();

function createAscSortListItem(
  value: EFilterFlags,
  groupLabel: string,
  sortLabel = $i18n.t('sort.ascending')
): ISortBar {
  return {
    label: `${groupLabel} ${sortLabel}`,
    value: `${value}${EFilterSeparator.SEPARATOR}${EFilterFlags.ASC}`
  };
}

function createDescSortListItem(
  value: EFilterFlags,
  groupLabel: string,
  sortLabel = $i18n.t('sort.descending')
): ISortBar {
  return {
    label: `${groupLabel}: ${sortLabel}`,
    value: `${value}${EFilterSeparator.SEPARATOR}${EFilterFlags.DESC}`
  };
}

const sortItems = [
  { ...createDescSortListItem(EFilterFlags.SCORE, $i18n.t('sort.accuracy'), $i18n.t('sort.highest')) },
  { ...createDescSortListItem(EFilterFlags.CREATED, $i18n.t('sort.creation'), $i18n.t('sort.latest')) },
  { ...createDescSortListItem(EFilterFlags.START_AT, $i18n.t('sort.start'), $i18n.t('sort.descending')) },
  { ...createAscSortListItem(EFilterFlags.OPENING_VALUE, $i18n.t('sort.call'), $i18n.t('sort.fromLowest')) },
  { ...createDescSortListItem(EFilterFlags.OPENING_VALUE, $i18n.t('sort.call'), $i18n.t('sort.fromHighest')) },
  { ...createAscSortListItem(EFilterFlags.ESTIMATE, $i18n.t('sort.estimate'), $i18n.t('sort.fromLowest')) },
  { ...createDescSortListItem(EFilterFlags.ESTIMATE, $i18n.t('sort.estimate'), $i18n.t('sort.fromHighest')) },
  { ...createDescSortListItem(EFilterFlags.POPULARITY, $i18n.t('sort.popularity'), $i18n.t('sort.highest')) }
];

const limitItems = [
  { label: '10', value: 10 },
  { label: '30', value: 30 },
  { label: '50', value: 50 },
  { label: '100', value: 100 }
];
</script>

<template>
  <div class="sort-container">
    <div class="sort-container__sort">
      <SelectValue
        v-model="sort"
        :clearable="false"
        :items="sortItems"
        :label="$t('sort.title')"
      />
    </div>

    <div class="sort-container__limit">
      <SelectValue
        v-model="limit"
        :clearable="false"
        :items="limitItems"
        :label="$t('sort.limitItems')"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '~core/cds-assets/scss/helpers' as *;

.sort-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: cdsSpace('md');

  @include cdsMediaQueryDown('md') {
    flex-direction: column;
  }

  &__sort,
  &__limit {
    display: flex;
    align-items: center;
    gap: cdsSpace('xs');

    @include cdsMediaQueryDown('md') {
      width: 100%;
      flex-direction: column;
      align-items: normal;
      gap: cdsSpace('3xs');
    }
  }
}
</style>
