<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core';

defineProps<{
  label: string;
}>();

const debounceSearch = useDebounceFn((value: string) => {
  textValue.value = value || '';
}, 350);

const textValue = defineModel<string>();
</script>

<template>
  <CdsText
    variant="normal-body"
    color="mid-emphasis-on-surface"
    class="d-flex"
  >
    {{ label }}
  </CdsText>
  <CdsTextField
    :model-value="textValue"
    @update:modelValue="debounceSearch($event as string)"
    :clearable="true"
    variant="outlined"
    type="default"
    size="regular"
  />
</template>
